@tailwind base;
@tailwind components;
@tailwind utilities;

/* .dark {
  background-color: #101214 !important;
} */

body {
  margin: 0;
  font-family: 'Ubuntu', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* // color: #273d5e; */
  /* background-color: #f6fcff; */

  /* position: fixed;
  overflow: hidden; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a {
  color: #00b9ff;
}

a:hover {
  font-weight: bold;
}

.disabled {
  pointer-events: none;
  opacity: 0.7;
}

.btn.ant-space {
  width: 100% !important;
  justify-content: center !important;
}

.btn .ant-space-item {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-drawer .ant-drawer-content-wrapper {
  max-height: 100% !important;
  overflow-y: auto !important;
}

.font-roboto-slab {
  font-family: 'Roboto Slab', serif;
}

.font-roboto {
  font-family: 'Roboto', sans-serif;
}

.font-fuzzy-bubbles {
  font-family: 'Fuzzy Bubbles', sans-serif;
}
/* 
.font-kalam {
  font-family: 'Kalam', sans-serif;
}

.font-caveat {
  font-family: 'Caveat', serif;
}

.font-courgette {
  font-family: 'Courgette', serif;
}

.font-ephesis {
  font-family: 'Ephesis', serif;
}

.font-changa {
  font-family: 'Changa', sans-serif;
}

.font-bruno-ace {
  font-family: 'Bruno Ace', sans-serif;
}

.font-arima {
  font-family: 'Arima', sans-serif;
}

.font-gabriela {
  font-family: 'Gabriela', sans-serif;
}

.font-glory {
  font-family: 'Glory', sans-serif;
} */

/* .font-rajdhani {
  font-family: 'Rajdhani', sans-serif;
}

.font-afacad {
  font-family: 'Afacad', sans-serif;
}

.font-gilda-display {
  font-family: 'Gilda Display', serif;
}

.font-podkova {
  font-family: 'Podkova', serif !important;
}

.font-inria-serif {
  font-family: 'Inria Serif', serif;
}

.font-caveat {
  font-family: 'Caveat', serif;
}

.font-courgette {
  font-family: 'Courgette', serif;
}

.font-pacifico {
  font-family: 'Pacifico', serif;
} */

hr {
  border-top: 1px solid #ff5dba;
  width: 100%;
  margin: 1.5rem 0;
}

.blinking {
  animation: blinking-anim 1s linear infinite;
}

@keyframes blinking-anim {
  50% {
    opacity: 0.4;
  }
}

.blink-5 {
  opacity: 1;
  animation-name: blink-anim-1;
  animation-duration: 0.25s;
  animation-timing-function: linear;
  animation-iteration-count: 5;
  animation-direction: alternate;
  animation-fill-mode: backwards;
}

@keyframes blink-anim-1 {
  50% {
    opacity: 0.6;
  }
}

.scailing-1 {
  opacity: 1;
  animation-name: scailing-anim-1;
  animation-duration: 1.5s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-fill-mode: backwards;
}

@keyframes scailing-anim-1 {
  50% {
    transform: scale(1.05);
  }
}

.scailing-1-5 {
  transform: scale(1.5);
  opacity: 1;
  animation-name: scailing-anim-1-5;
  animation-duration: 1.5s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-fill-mode: backwards;
}

@keyframes scailing-anim-1-5 {
  50% {
    transform: scale(1.55);
  }
}

@media (max-width: 1023px) {
  .ant-dropdown-menu-item {
    padding: 12px 18px !important;
    font-size: 1.2rem !important;
  }

  .ant-dropdown-menu-item-icon {
    font-size: 1.2rem !important;
    margin-inline-end: 12px !important;
  }
}
